import { Box, Grid } from '@mui/material';
import { ApiGet } from 'helpers/Api/ApiData';
import { useEffect, useState } from 'react';
import { MediaCard } from './MediaCards';
import { MediaHeader } from './MediaHeader';

const MediaPage = () => {
  const [allMedia, setAllMedia] = useState([]);

  useEffect(() => {
    ApiGet('admin/get-all-media').then((res: any) => {
      setAllMedia(res.data);
    });
  }, []);

  return (
    <Box pb={8}>
      <MediaHeader />
      <Box maxWidth="1200px" margin="auto">
        <Grid container spacing={8} pt={4} pb="100px">
          {allMedia.map((media: any) => (
            <Grid key={media._id} item md={4} xs={12}>
              <MediaCard
                headline={media.headline}
                cover_image={media.cover_image}
                media_name={media.name}
                link={media.link}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default MediaPage;
